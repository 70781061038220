import Vue from "vue";
import * as Api from "../../axios-generated/backoffice/";
// @ts-ignore
import axios from "@axios";

const PRODUCT_API = new Api.ProductsApi(
  undefined,
  Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL,
  axios
);

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProducts() {
      return new Promise((resolve, reject) => {
        PRODUCT_API.findProducts()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProductByID(ctx, { productID }) {
      return new Promise((resolve, reject) => {
        PRODUCT_API.findProductByID(productID)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createProduct(ctx, { newProduct }) {
      return new Promise((resolve, reject) => {
        PRODUCT_API.createProduct(newProduct)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editProduct(ctx, { editProduct }) {
      return new Promise((resolve, reject) => {
        PRODUCT_API.updateProduct(editProduct.ID, editProduct)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
