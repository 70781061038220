var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":function($event){return _vm.submitForm(_vm.newProduct)}}},[_c('tab-content',{attrs:{"title":"Produit","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',{staticClass:"ml-2 mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-avatar',{attrs:{"src":"" + _vm.publicPath +
                    "images/products/" +
                    "" + (_vm.newProduct.logo),"size":"104px","rounded":""}})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"mb-1"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.newProduct.name)+" ")])]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Logo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Logo","label-for":"Logo","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"logo","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.productImageOptions},model:{value:(_vm.newProduct.logo),callback:function ($$v) {_vm.$set(_vm.newProduct, "logo", $$v)},expression:"newProduct.logo"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Banner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Banner","label-for":"Banner","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"banner","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.productImageOptions},model:{value:(_vm.newProduct.banner),callback:function ($$v) {_vm.$set(_vm.newProduct, "banner", $$v)},expression:"newProduct.banner"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nom","label-for":"nom"}},[_c('validation-provider',{attrs:{"name":"Nom","vid":"Nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"MyProduct"},model:{value:(_vm.newProduct.name),callback:function ($$v) {_vm.$set(_vm.newProduct, "name", $$v)},expression:"newProduct.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Code technique","label-for":"code"}},[_c('validation-provider',{attrs:{"name":"Code","vid":"Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"product"},model:{value:(_vm.newProduct.code),callback:function ($$v) {_vm.$set(_vm.newProduct, "code", $$v)},expression:"newProduct.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description","vid":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":"My custom description"},model:{value:(_vm.newProduct.description),callback:function ($$v) {_vm.$set(_vm.newProduct, "description", $$v)},expression:"newProduct.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Lien détail","label-for":"details_link"}},[_c('validation-provider',{attrs:{"name":"details_link","vid":"details_link"}},[_c('b-form-input',{attrs:{"id":"url","placeholder":"https://myproduct-details.fr"},model:{value:(_vm.newProduct.details_link),callback:function ($$v) {_vm.$set(_vm.newProduct, "details_link", $$v)},expression:"newProduct.details_link"}})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Documentation Openapi","label-for":"documentation_link"}},[_c('validation-provider',{attrs:{"name":"documentation_link","vid":"documentation_link"}},[_c('b-form-input',{attrs:{"id":"url","placeholder":"0.1.0_code_realestate_v2.json"},model:{value:(_vm.newProduct.documentation_link),callback:function ($$v) {_vm.$set(_vm.newProduct, "documentation_link", $$v)},expression:"newProduct.documentation_link"}})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Version","label-for":"Version"}},[_c('validation-provider',{attrs:{"name":"Version","vid":"Version","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"version","state":errors.length > 0 ? false : null,"placeholder":"v1.0.1"},model:{value:(_vm.newProduct.version),callback:function ($$v) {_vm.$set(_vm.newProduct, "version", $$v)},expression:"newProduct.version"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Url","label-for":"url"}},[_c('validation-provider',{attrs:{"name":"Url","vid":"Url","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"url","state":errors.length > 0 ? false : null,"placeholder":"https://myproduct.fr"},model:{value:(_vm.newProduct.URL),callback:function ($$v) {_vm.$set(_vm.newProduct, "URL", $$v)},expression:"newProduct.URL"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Categorie","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Categorie","label-for":"Categorie","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categoryOptions,"reduce":function (val) { return val.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.newProduct.category),callback:function ($$v) {_vm.$set(_vm.newProduct, "category", $$v)},expression:"newProduct.category"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Statut","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Statut","label-for":"Statut","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"status","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.newProduct.status),callback:function ($$v) {_vm.$set(_vm.newProduct, "status", $$v)},expression:"newProduct.status"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }