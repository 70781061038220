<template>
  <b-row class="mt-1">
    <b-col cols="12">
      <product-create-form-wizard :new-product="newProduct" />
    </b-col>
  </b-row>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol } from "bootstrap-vue";
import ProductCreateFormWizard from "./ProductCreateFormWizard.vue";
import productStoreModule from "../productStoreModule";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    ProductCreateFormWizard,
  },
  mixins: [PageBoxed],
  setup() {
    const newProduct = ref({});

    const PRODUCT_APP_STORE_MODULE_NAME = "app-product";

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME))
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, productStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME))
        store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME);
    });

    return {
      newProduct,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
